import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/icons/letter-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/portfolio-icon-2.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/profile-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/jobs/JobList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SearchForm/SearchForm.tsx");
